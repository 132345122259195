import React from 'react';
import './DataIntegration.css'; // Import your CSS file for styling

const DataIntegration = () => {
  return (
    <div className="data-integration">
      <div className="data-integration-intro">
        <h1>Data Integration</h1>
        <p>Combine data from disparate sources to create a unified and coherent view for better insights and decision-making.</p>
      </div>
      <section className="data-integration-details">
        <div className="data-integration-point">
          <h2>Unified Data View</h2>
          <p>Integrate data from various sources to provide a comprehensive and unified view for analysis.</p>
        </div>
        <div className="data-integration-point">
          <h2>Seamless Data Connectivity</h2>
          <p>Ensure smooth and efficient connections between disparate data systems and applications.</p>
        </div>
        <div className="data-integration-point">
          <h2>Real-Time Data Sync</h2>
          <p>Enable real-time synchronization of data to keep your insights up-to-date.</p>
        </div>
        <div className="data-integration-point">
          <h2>Scalable Integration</h2>
          <p>Scale your data integration solutions to handle growing data volumes and complexity.</p>
        </div>
      </section>
    </div>
  );
}

export default DataIntegration;
