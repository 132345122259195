import React, { useState } from 'react';
import './Charting.css';
import chartingImage from '../assets/img/charting_image.png';
import barChartImage from '../assets/img/bar_charts.png';
import pieChartImage from '../assets/img/pie_chart.png';
import lineChartImage from '../assets/img/line_chart.svg';

const Charting = () => {
    const [showExpanded, setShowExpanded] = useState(false);

    const handleLearnMoreClick = () => {
        setShowExpanded(!showExpanded);
    };

    return (
        <div className="charting-container">
            <div className="charting-header">
                <h1 className="section-title">Transformative Data Visualizations</h1>
                <p className="section-charting-text">
                    We create sophisticated, AI-powered visualizations that provide more than just data points. With Excavate Research, data is translated into intuitive and interactive charts that help make strategic decisions clearer and faster.
                </p>
                {/* <h3 className="highlights-title">Highlights:</h3> */}
                <div className="highlights-cards">
                    <div className="highlight-card">
                        <h4 className="highlight-card-title">AI-Assisted Chart Creation</h4>
                        <p className="highlight-card-description">
                            Automated chart generation based on data patterns.
                        </p>
                    </div>
                    <div className="highlight-card">
                        <h4 className="highlight-card-title">Interactive Dashboards</h4>
                        <p className="highlight-card-description">
                            Dynamic charting that updates in real-time based on the latest data.
                        </p>
                    </div>
                    <div className="highlight-card">
                        <h4 className="highlight-card-title">Customization</h4>
                        <p className="highlight-card-description">
                            Tailor every chart to your exact specifications using AI-assisted design recommendations.
                        </p>
                    </div>
                </div>
            </div>

            <button className="learn-more-button" onClick={handleLearnMoreClick}>
                {showExpanded ? 'Show Less' : 'Learn More'}
            </button>

            <div className={`expanded-explanation ${showExpanded ? 'show' : ''}`}>
                <h2>Expanded Explanation</h2>
                <p>
                    Excavate’s charting services offer advanced visual representation of data using AI-powered tools. By utilizing libraries such as D3.js and Plotly, we ensure dynamic, interactive charts that help businesses quickly identify trends. AI helps suggest the best chart types based on the data patterns, and the visualization updates in real-time as the data changes.
                </p>
                <h3>Case Study:</h3>
                <p>
                    A healthcare organization required interactive visualizations to track patient outcomes across various treatments. Using AI-assisted charting, we created a dynamic dashboard that showed real-time trends in patient recovery rates. The AI system selected the most effective visual formats, helping decision-makers quickly interpret the data. As a result, the organization improved its operational decisions, reducing the time spent analyzing reports by 30%.
                </p>
            </div>

            <div className="charting-content">
                <div className="chart-section">
                    <div className="chart-info">
                        <h3 className="chart-title">Bar Charts</h3>
                        <p className="chart-description">
                            Bar charts are ideal for comparing quantities across different categories. They provide a clear visual comparison and help in identifying trends and outliers over time. Perfect for analyzing performance metrics or sales data.
                        </p>
                    </div>
                    <img src={barChartImage} alt="Bar Chart" className="chart-image" />
                </div>
                
                <div className="chart-section reversed">
                    <img src={pieChartImage} alt="Pie Chart" className="chart-image" />
                    <div className="chart-info">
                        <h3 className="chart-title">Pie Charts</h3>
                        <p className="chart-description">
                            Pie charts effectively visualize proportions and percentages. They help in understanding the composition of data and are ideal for showing parts of a whole, such as market share or survey results.
                        </p>
                    </div>
                </div>

                <div className="chart-section">
                    <div className="chart-info">
                        <h3 className="chart-title">Line Charts</h3>
                        <p className="chart-description">
                            Line charts are useful for tracking changes over time. They illustrate data points connected by lines, making it easy to identify trends, fluctuations, and patterns in time series data.
                        </p>
                    </div>
                    <img src={lineChartImage} alt="Line Chart" className="chart-image" />
                </div>

                <div className="chart-section reversed">
                    <img src={chartingImage} alt="Interactive Graphs" className="chart-image" />
                    <div className="chart-info">
                        <h3 className="chart-title">Interactive Graphs</h3>
                        <p className="chart-description">
                            Interactive graphs provide dynamic visualizations with features like zooming, filtering, and data exploration. They are perfect for in-depth analysis and uncovering insights from complex datasets.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Charting;
