import React from 'react';
import './DataExtraction.css'; // Import your CSS file for styling

const DataExtraction = () => {
  return (
    <div className="data-extraction-container">
      <div className="data-extraction-header">
        <h1>ETL Services: Extract, Transform, Load</h1>
        <p>Efficiently move and transform data across systems with our AI-enhanced ETL processes.</p>
      </div>
      <section className="data-extraction-content">
        <div className="data-extraction-item">
          <h2>Automated Data Extraction</h2>
          <p>Use AI-driven automation to extract data from multiple sources, including databases, APIs, and flat files.</p>
        </div>
        <div className="data-extraction-item">
          <h2>Intelligent Data Transformation</h2>
          <p>Transform data using ML algorithms to clean, normalize, and prepare it for analysis.</p>
        </div>
        <div className="data-extraction-item">
          <h2>Seamless Data Loading</h2>
          <p>Load data into your desired databases or analytics platforms with precision and speed, minimizing data loss or corruption.</p>
        </div>
        <div className="data-extraction-item">
          <h2>Cost-Efficient Workflows</h2>
          <p>Reduce manual efforts and operational costs by automating the entire ETL process.</p>
        </div>
      </section>
    </div>
  );
}

export default DataExtraction;
