import React from 'react';
import './Contribution.css';
import children from '../assets/img/children.jpg';
import successStoryImage from '../assets/img/careers.png';
import testimonialImage from '../assets/img/children.jpg';
import treePlantingImage from '../assets/img/plant-tree.jpg';

function Contribution() {
  return (
    <div className="contribution-page">
      <div className="info-section">
        <h2>About Our Initiatives</h2>
        <p>
          Our company is committed to making a meaningful difference in the lives of children and in the environment. We work closely with children’s foundations to ensure better access to education, healthcare, and essential services. Additionally, we actively participate in environmental projects, such as tree planting and other green initiatives. Your involvement helps us continue these positive efforts.
        </p>
      </div>

      <div className="featured-section">
        <h2>Our Impact</h2>
        <div className="featured-content">
          <img src={successStoryImage} alt="Success Story" className="featured-image" />
          <div className="featured-text">
            <h3>Success Stories</h3>
            <p>
              Thanks to the collective support we receive, we have seen amazing transformations in the lives of children. Our programs have helped provide access to education, healthcare, and vital resources, allowing children to thrive. Here are a few success stories that highlight the positive changes made possible by your contribution.
            </p>
          </div>
        </div>
      </div>

      <div className="testimonials-section">
        <h2>What Our Beneficiaries Say</h2>
        <div className="testimonial">
          <img src={testimonialImage} alt="Testimonial" className="testimonial-image" />
          <div className="testimonial-text">
            <p>
              "With the support provided, my child was able to attend school and receive the healthcare they needed. We are deeply thankful for the opportunities and hope this has brought to our family." — A grateful parent
            </p>
          </div>
        </div>
      </div>

      <div className="cta-section">
        <h2>Get Involved</h2>
        <div className="cta-content">
          <img src={treePlantingImage} alt="Tree Planting" className="cta-image" />
          <div className="cta-text">
            <h3>Plant Trees for a Greener Future</h3>
            <p>
              Join us in creating a positive impact on the environment through our tree planting programs. Your involvement helps us plant more trees and restore natural ecosystems, contributing to a healthier planet. Alongside supporting children's well-being, this initiative promotes sustainability for future generations.
            </p>
            <p>
              Together, we can make a lasting impact on both children's futures and the health of our planet. Become a part of our efforts today and help us continue this important work!
            </p>
            {/* <button className="cta-button">Join Us</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contribution;
