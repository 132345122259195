import React from 'react';
import './PipelineDevelopment.css'; // Import your CSS file for styling

const PipelineDevelopment = () => {
  return (
    <div className="pipeline-development">
      <div className="pipeline-development-intro">
        <h1>Pipeline Development and Management</h1>
        <p>Develop robust, scalable data pipelines that integrate and process data in real-time.</p>
      </div>
      <section className="pipeline-development-details">
        <div className="pipeline-development-point">
          <h2>AI-Driven Pipelines</h2>
          <p>Build intelligent data pipelines that automatically adapt to changing data conditions using machine learning.</p>
        </div>
        <div className="pipeline-development-point">
          <h2>Real-Time Data Processing</h2>
          <p>Implement pipelines that handle real-time data ingestion and transformation, allowing for live insights and analytics.</p>
        </div>
        <div className="pipeline-development-point">
          <h2>End-to-End Management</h2>
          <p>Monitor, maintain, and optimize pipelines for maximum efficiency, ensuring your data flows without interruption.</p>
        </div>
        <div className="pipeline-development-point">
          <h2>Scalability and Flexibility</h2>
          <p>Easily scale pipelines to accommodate growing data volumes, enabling seamless expansion.</p>
        </div>
      </section>
    </div>
  );
}

export default PipelineDevelopment;
