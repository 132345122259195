import React from 'react';
import { Container } from 'reactstrap';
import './Team.css';
import MemberSlideshow from './MemberSlideshow'; // Import the slideshow

const Team = () => {
  return (
    <section id="team" className="team-section">
      <Container>
        <div className="team-content">
          <h2>Meet Our Team</h2>
          <p>Our team is dedicated to delivering the best service and achieving outstanding results.</p>
        </div>
        <div className="team-slideshow">
          <MemberSlideshow /> {/* Using the slideshow component */}
        </div>
      </Container>
    </section>
  );
};

export default Team;
