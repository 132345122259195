import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MemberSlideshow.css'; // Import CSS for Slideshow component
import slide1 from '../assets/img/MicrosoftTeams-image (1).png';
import slide2 from '../assets/img/MicrosoftTeams-image (2).png';
import slide3 from '../assets/img/MicrosoftTeams-image (3).png';
import slide4 from '../assets/img/MicrosoftTeams-image (4).png';

const MemberSlideshow = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true
  };

  return (
    <Slider {...settings} className="slideshow-container">
      <div>
        <img src={slide1} alt="Slide 1" className="slide-image" />
      </div>
      <div>
        <img src={slide2} alt="Slide 2" className="slide-image" />
      </div>
      <div>
        <img src={slide3} alt="Slide 3" className="slide-image" />
      </div>
      <div>
        <img src={slide4} alt="Slide 4" className="slide-image" />
      </div>
    </Slider>
  );
}

export default MemberSlideshow;
