import React from 'react';
import './DataGovernance.css'; // Import your CSS file for styling

const DataGovernance = () => {
  return (
    <div className="data-governance">
      <div className="data-governance-intro">
        <h1>Data Governance</h1>
        <p>Implement robust data governance strategies to ensure data integrity, security, and compliance.</p>
      </div>
      <section className="data-governance-details">
        <div className="data-governance-point">
          <h2>Data Integrity</h2>
          <p>Establish policies to maintain data accuracy, consistency, and reliability.</p>
        </div>
        <div className="data-governance-point">
          <h2>Data Security</h2>
          <p>Implement security measures to protect data from unauthorized access and breaches.</p>
        </div>
        <div className="data-governance-point">
          <h2>Compliance Management</h2>
          <p>Ensure compliance with industry regulations and standards for data management.</p>
        </div>
        <div className="data-governance-point">
          <h2>Data Stewardship</h2>
          <p>Assign roles and responsibilities to manage data assets effectively and ethically.</p>
        </div>
      </section>
    </div>
  );
}

export default DataGovernance;
