import React from 'react';
import './CloudDataMigration.css'; // Import your CSS file for styling

const CloudDataMigration = () => {
  return (
    <div className="cloud-data-migration">
      <div className="cloud-data-intro">
        <h1>Cloud Data Migration</h1>
        <p>Seamlessly migrate your data to the cloud with minimal disruption and maximum efficiency.</p>
      </div>
      <section className="cloud-data-details">
        <div className="cloud-data-point">
          <h2>Effortless Migration</h2>
          <p>Move data to the cloud with minimal downtime and disruption to your business operations.</p>
        </div>
        <div className="cloud-data-point">
          <h2>Data Integrity</h2>
          <p>Ensure data accuracy and completeness during the migration process to prevent data loss.</p>
        </div>
        <div className="cloud-data-point">
          <h2>Scalable Solutions</h2>
          <p>Easily scale your data storage and processing capabilities in the cloud according to your needs.</p>
        </div>
        <div className="cloud-data-point">
          <h2>Cost Optimization</h2>
          <p>Optimize costs with flexible cloud pricing models and reduce infrastructure expenses.</p>
        </div>
      </section>
    </div>
  );
}

export default CloudDataMigration;
