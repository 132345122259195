import React from 'react';
import './Consulting.css'; // Import the CSS file

const Consulting = () => {
  return (
    <section id="consulting">
      <div className="consulting-container">
        <h2>Expert Consulting Services</h2>
        <p className="intro-text">
          Unlock your business's potential with our expert consulting services. From strategic planning to operational optimization, we provide tailored solutions designed to drive growth and success.
        </p>
        <p className="intro-text">
          Whether you're aiming to enhance your technology infrastructure, improve efficiency, or navigate complex change, our experienced team delivers actionable insights and hands-on support every step of the way.
        </p>
        <div className="services-list">
          <div className="service-card-new">
            <h3>Business Strategy Consulting</h3>
            <p>Craft growth strategies that align with your vision and market demands to propel your business forward.</p>
          </div>
          <div className="service-card-new">
            <h3>Technology Advisory</h3>
            <p>Leverage the latest technologies to streamline operations, enhance innovation, and achieve a competitive edge.</p>
          </div>
          <div className="service-card-new">
            <h3>Operational Efficiency Improvement</h3>
            <p>Optimize processes, reduce costs, and improve performance across all operational aspects.</p>
          </div>
          <div className="service-card-new">
            <h3>Project Management</h3>
            <p>Ensure successful project execution from inception to completion, on time and within budget.</p>
          </div>
          <div className="service-card-new">
            <h3>Change Management</h3>
            <p>Implement organizational change smoothly, empowering your team and minimizing disruption.</p>
          </div>
        </div>
        <p className="cta-text">Let us help you build a smarter, more agile business. Contact us today to learn more.</p>
        <a href="/contact" className="cta-button">Get in Touch</a>
      </div>
    </section>
  );
};

export default Consulting;
