import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; // Slick core CSS
import 'slick-carousel/slick/slick-theme.css'; // Slick theme CSS
import './Slideshow.css'; // Import the custom CSS

import slide1 from '../assets/img/DataAnalystImage.jpg';
import slide2 from '../assets/img/AnalyticsImage.jpg';
import slide3 from '../assets/img/SurveyImage.jpg';
import slide4 from '../assets/img/training-program.png';
import slide5 from '../assets/img/TeamImage.jpg';
import slide6 from '../assets/img/AIImage.jpg';


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
};

const Slideshow = () => {
  return (
    <section className="slideshow-container"> {/* Main container */}
      <Slider {...settings}> {/* Slick Slider with settings */}
        {/* Each slide has a dedicated div with an image container */}
        <div className="slideshow-slide"> 
          <div className="slideshow-image-container"> 
            <img src={slide1} alt="Slide 1" />
          </div>
        </div>

        <div className="slideshow-slide">
          <div className="slideshow-image-container">
            <img src={slide2} alt="Slide 2" />
          </div>
        </div>

        <div className="slideshow-slide">
          <div className="slideshow-image-container">
            <img src={slide3} alt="Slide 3" />
          </div>
        </div>

        <div className="slideshow-slide">
          <div className="slideshow-image-container">
            <img src={slide4} alt="Slide 4" />
          </div>
        </div>

        <div className="slideshow-slide">
          <div className="slideshow-image-container">
            <img src={slide5} alt="Slide 5" />
          </div>
        </div>
        <div className="slideshow-slide">
          <div className="slideshow-image-container">
            <img src={slide6} alt="Slide 5" />
          </div>
        </div>
      </Slider> {/* Close Slider */}
    </section>
  );
};

export default Slideshow;
