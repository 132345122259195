// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} Excavate Research & Analysis Pvt. Ltd.</p>
    </footer>
  );
}

export default Footer;
