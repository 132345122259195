import React from 'react';
import './Home.css';
import AI from '../assets/img/HI-and-AI.png';
import Mission from '../components/Mission';

const Home = () => {
  return (
    <div className="home-section">
      <div className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Empower Your Business with AI-Driven Insights</h1>
          <p className="hero-description">
            Discover the future of market research with intelligent data analytics that drives innovation and decision-making.
          </p>
        </div>
      </div>
      <Mission />
      <div className="services-section">
        <h2 className="section-title">Our AI-Powered Services</h2>
        <div className="services-grid">
          <div className="service-card">
            <h3>Predictive Analytics</h3>
            <p>Gain deep insights into market trends and consumer behavior using advanced predictive models.</p>
          </div>
          <div className="service-card">
            <h3>Data Mining &amp; AI</h3>
            <p>Unlock the power of data to unearth actionable intelligence that gives you a competitive edge.</p>
          </div>
          <div className="service-card">
            <h3>Automation Solutions</h3>
            <p>Streamline your processes and enhance productivity with AI-powered automation solutions.</p>
          </div>
          <div className="service-card">
            <h3>Market Research & Consulting</h3>
            <p>Leverage our expertise in market research and consulting to make informed strategic decisions and gain a deeper understanding of your industry.</p>
          </div>
        </div>
      </div>

      {/* New Section for AI content and image */}
      <div className="ai-home-section">
        <div className="ai-home-content">
          <h2>Revolutionizing Industries with AI</h2>
          <p>
            Artificial Intelligence is transforming the way businesses operate by automating complex tasks, analyzing vast amounts of data, and providing insights that were previously unattainable. Our AI solutions empower companies to innovate faster, optimize operations, and stay ahead in a rapidly evolving digital world.
          </p>
          <p>
            Whether it's predictive analytics, automation, or deep learning, AI is redefining industries by unlocking new potentials in every field.
          </p>
        </div>
        <div className="ai-home-image">
          <img src={AI} alt="AI Revolution" />
        </div>
      </div>
    </div>
  );
}

export default Home;
