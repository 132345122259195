import React, { useState } from 'react';
import './Dashboards.css';
import dashboardImage from '../assets/img/dashboard_new.png';
import filterImage from '../assets/img/Customizable Filters.png';
import menuImage from '../assets/img/dashboard.png';
import insightsImage from '../assets/img/slide3.webp';
import updateImage from '../assets/img/dashboards_image.png';
import excelImage from '../assets/img/excelImage.jpg';

const Dashboards = () => {
    const [showExpanded, setShowExpanded] = useState(false);

    const handleLearnMoreClick = () => {
        setShowExpanded(!showExpanded);
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <h2 className="section-title">Tailored Business Dashboards</h2>
                <p className="section-dash-text">
                    Our dashboards offer an extensive overview of your business performance. Explore our customizable filters, quick navigation menus, real-time insights, and automatic updates to make data-driven decisions effortlessly.
                </p>
                <div className="intro-section">
                    <h2 className="bydefault-title">Dashboards and Visualization</h2>
                    <p>
                        Our dashboards offer the next generation of business intelligence, integrating AI and deep learning to deliver real-time, actionable insights. Built on the robust React and Python ecosystem, our solutions are tailored to visualize data in a way that drives impactful decisions.
                    </p>
                    <h3 className="intro-key-benefits">Key Benefits:</h3>
                    <div className="benefits-container">
                        <div className="benefit-card">
                            <h4 className="benefit-title">Real-Time Data Dashboards</h4>
                            <p className="benefit-description">
                                AI-driven data visualization tools that provide instant insights.
                            </p>
                        </div>
                        <div className="benefit-card">
                            <h4 className="benefit-title">Predictive Visualizations</h4>
                            <p className="benefit-description">
                                Machine learning algorithms allow your dashboard to predict trends, helping you stay ahead of the curve.
                            </p>
                        </div>
                        <div className="benefit-card">
                            <h4 className="benefit-title">Cost-Effective</h4>
                            <p className="benefit-description">
                                Using open-source technologies, our dashboards offer more customization and lower costs than traditional BI tools like Qlik.
                            </p>
                        </div>
                    </div>
                    <button className="learn-more-button" onClick={handleLearnMoreClick}>
                        {showExpanded ? 'Show Less' : 'Learn More'}
                    </button>
                </div>
                {/* Expanded Explanation Section */}
                <div className={`expanded-explanation ${showExpanded ? 'show' : ''}`}>
                    <h2 className="expanded-title">Expanded Details on Dashboards</h2>
                    <p>
                        Excavate builds AI-integrated dashboards using React and Python, offering unparalleled flexibility and real-time data insights. Our dashboards not only visualize data but also apply machine learning models to predict future outcomes based on current trends. AI further helps optimize performance, ensuring that large datasets are visualized quickly and efficiently.
                    </p>
                    <h3 className="case-study-title">Case Study:</h3>
                    <p>
                        A retail chain needed a real-time sales monitoring dashboard to optimize its stock levels across 200 stores. Excavate built a custom dashboard that used machine learning to predict sales spikes based on historical trends, external factors like weather, and real-time data inputs. The dynamic visualization helped the chain increase sales by 12%, reducing stock-outs by 25%. The real-time insights provided by our dashboard led to a 20% reduction in inventory holding costs.
                    </p>
                </div>
            </div>

            <div className="dashboard-main">
                <img className="dashboard-main-image" src={dashboardImage} alt="Dashboard Overview" />
                <div className="dashboard-main-info">
                    <h3 className="dashboard-main-title">Overview of Dashboard Features</h3>
                    <p className="dashboard-main-description">
                        Our dashboard system offers a comprehensive view of your business metrics with intuitive, interactive features. From customizable widgets to advanced filtering options, each dashboard is designed to provide actionable insights tailored to your needs.
                    </p>
                </div>
            </div>

            <div className="dashboard-features">
                <div className="feature-dash-card">
                    <img className="feature-dash-image" src={filterImage} alt="Filter Options" />
                    <h4 className="feature-dash-title">Customizable Filters</h4>
                    <p className="feature-dash-description">
                        Apply and save custom filters to view your data in ways that suit your needs, ensuring you get the most relevant insights.
                    </p>
                </div>
                <div className="feature-dash-card">
                    <img className="feature-dash-image" src={menuImage} alt="Navigation Menu" />
                    <h4 className="feature-dash-title">Quick Navigation Menus</h4>
                    <p className="feature-dash-description">
                        Easily navigate through your dashboard with our intuitive menus and shortcut keys.
                    </p>
                </div>
                <div className="feature-dash-card">
                    <img className="feature-dash-image" src={insightsImage} alt="Real-Time Insights" />
                    <h4 className="feature-dash-title">Real-Time Insights</h4>
                    <p className="feature-dash-description">
                        Receive up-to-date information and predictive insights with real-time data updates.
                    </p>
                </div>
                <div className="feature-card">
                    <img className="feature-dash-image" src={updateImage} alt="Automatic Updates" />
                    <h4 className="feature-dash-title">Automatic Updates</h4>
                    <p className="feature-dash-description">
                        Ensure your data is always current with automatic updates.
                    </p>
                </div>
                <div className="feature-card">
                    <img className="feature-dash-image" src={excelImage} alt="Excel Integration" />
                    <h4 className="feature-dash-title">Excel Integration</h4>
                    <p className="feature-dash-description">
                        Seamlessly import and export data to and from Excel for extended analysis and reporting.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Dashboards;
